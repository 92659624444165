import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Hero from '../components/hero';
import { H2, P } from '../components/typography';
import Container from '../components/container';
import Section from '../components/section';
import ProductList from '../components/productList';
import ProductCard from '../components/productCard';
import NavDropdown from '../components/navDropdown';
import Observable from '../components/observable';
import { fluidImageType, nodesOf } from '../helper/prop-types';

class ProductPage extends Component {
    static propTypes = {
        data: PropTypes.shape({
            file: fluidImageType,
            allDatoCmsProduct: nodesOf(
                PropTypes.shape({
                    title: PropTypes.string,
                    slug: PropTypes.string,
                    description: PropTypes.string,
                    image: PropTypes.shape({
                        fluid: PropTypes.object,
                    }),
                })
            ),
            allDatoCmsProductcategory: nodesOf(
                PropTypes.shape({
                    slug: PropTypes.string,
                    title: PropTypes.string,
                    description: PropTypes.string,
                })
            ),
        }).isRequired,
    };

    state = {
        activeCategory: null,
    };

    /**
     * Setzt das gerade aktive Rezept für die Dropdown-Navigation
     * @param {string} slug
     */
    updateActiveCategory(slug) {
        window.history.pushState({}, 'Kategorie', `/produkte#${slug}`);
        this.setState({ activeCategory: slug });
    }

    /**
     * Render
     */
    render() {
        const { data } = this.props;
        const { activeCategory } = this.state;
        const categories = data.allDatoCmsProductcategory.edges.map(
            ({ node: category }) => category
        );

        return (
            <Layout
                conclusionBoxes={['recipes', 'aboutus']}
                title="Die Original Lechtaler Wurstvielfalt"
                description="Ob Bergwurst, Knabberstangerl oder Leberkäs – entdecken Sie die ganze Vielfalt unserer Produkte. Hier finden Sie alle auf einen Blick!"
            >
                <Hero
                    title="Die Original Lechtaler Sortenvielfalt"
                    image={data.file.childImageSharp.fluid}
                />
                <Section>
                    <Container maxWidth="small">
                        <H2 textCentered largeFont>
                            Einzigartig bayerischer Wurstgenuss
                        </H2>
                        <P textCentered>
                            Entdecke beste Wurstwaren nach traditionellen Rezepturen aus
                            Deutschlands Genussregion Nummer 1. Ob klassisch aufs Brot, zur
                            Vesperplatte oder für Unterwegs: Die Original Lechtaler garantieren
                            bayerischen Wurstgenuss zu jedem Anlass.
                        </P>
                    </Container>
                </Section>
                <NavDropdown
                    links={categories.map(({ slug, title }) => ({
                        to: `/produkte/#${slug}`,
                        title,
                        active: activeCategory === slug,
                    }))}
                />

                {categories.map(category => (
                    <Section key={category.title} id={category.slug} padding="60px 10px 30px">
                        <Observable
                            once={false}
                            callback={() => this.updateActiveCategory(category.slug)}
                        >
                            <Container maxWidth="small">
                                <H2 textCentered as="h3">
                                    {category.title}
                                </H2>
                                <P textCentered gap={{ small: 'l', medium: 'xxl' }}>
                                    {category.description}
                                </P>
                            </Container>
                            <Container maxWidth="medium">
                                <ProductList>
                                    {data.allDatoCmsProduct.edges
                                        .filter(
                                            ({ node: product }) =>
                                                product.category.title === category.title
                                        )
                                        .map(({ node: product }) => (
                                            <ProductCard
                                                slug={product.slug}
                                                image={product.image}
                                                key={product.title}
                                                title={product.title}
                                                description={product.description}
                                            />
                                        ))}
                                </ProductList>
                            </Container>
                        </Observable>
                    </Section>
                ))}
            </Layout>
        );
    }
}

export default ProductPage;

export const query = graphql`
    query {
        file(relativePath: { eq: "keyvisual/keyvisual-bread.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
        allDatoCmsProduct {
            edges {
                node {
                    title
                    slug
                    description
                    image {
                        fluid(maxWidth: 315) {
                            ...GatsbyDatoCmsFluid
                        }
                        alt
                        title
                    }
                    category {
                        title
                        description
                    }
                }
            }
        }
        allDatoCmsProductcategory {
            edges {
                node {
                    id
                    slug
                    title
                    description
                }
            }
        }
    }
`;
