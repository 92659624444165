import styled from 'styled-components';
import { mq } from '../helper/stylehelper';

const ProductList = styled.ul`
    margin: 0;
    padding: 0;
    ${mq.large`
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
    `};
`;

export default ProductList;
