import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observeIntersection } from '../helper/utils';

class Observable extends Component {
    static propTypes = {
        once: PropTypes.bool,
        callback: PropTypes.func.isRequired,
        children: PropTypes.node.isRequired,
    };

    static defaultProps = {
        once: true,
    };

    element = React.createRef();

    observer = null;

    /**
     * Lifecycle Hook bei Mount
     */
    componentDidMount() {
        const { callback, once } = this.props;
        this.observer = observeIntersection(
            this.element.current,
            entry => {
                if (once) {
                    this.observer.disconnect();
                    this.observer = null;
                }

                if (entry.isIntersecting) {
                    callback.call(null);
                }
            },
            {
                rootMargin: '0px 0px -90% 0px',
            }
        );
    }

    /**
     * Lifecycle Hook bei Unmount
     */
    componentWillUnmount() {
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    /**
     * Rendert die Kinder
     */
    render() {
        const { children } = this.props;
        return <div ref={this.element}>{children}</div>;
    }
}

export default Observable;
