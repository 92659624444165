import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { rem } from 'polished';
import StickyNav from './stickyNav';
import { colors } from '../helper/variables';

import angleDown from '../images/angle-down.svg';

const NavItem = styled(Link)`
    border-top: 1px dashed rgba(255, 255, 255, 0.2);
    color: ${colors.white};
    display: block;
    flex: 0 0 3em;
    line-height: 1.5em;
    order: 1;
    padding: 0.75em 1em;
    position: relative;
    text-decoration: none;

    &.is-active {
        border-top: 0;
        order: 0;
        overflow: hidden;
        padding-right: ${rem(50)};
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: break-word;
    }
    &:first-child {
        border-top: none;
    }
`;

const NavDropdown = styled(({ className, links }) => (
    <StickyNav>
        <div className={className}>
            {links.map(({ to, title, active }) => (
                <NavItem key={`link--${to}`} to={to} className={active ? 'is-active' : null}>
                    {title}
                </NavItem>
            ))}
        </div>
    </StickyNav>
))`
    background-color: ${colors.blueDark};
    box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.4);
    color: ${colors.white};
    display: flex;
    flex-direction: column;
    height: auto;
    left: 0;
    list-style-type: none;
    margin: 0;
    max-height: 3em;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &::after {
        color: ${colors.white};
        content: url(${angleDown});
        display: block;
        max-width: ${rem(13)};
        position: absolute;
        right: 1em;
        top: 0.8em;
        transition: transform 200ms;
    }

    &:hover {
        max-height: 100vh;
        transition-delay: 0;

        &::after {
            transform: rotate(540deg);
        }
    }
`;

NavDropdown.propTypes = {
    className: PropTypes.string,
    links: PropTypes.arrayOf(
        PropTypes.shape({
            to: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        })
    ),
};

export default NavDropdown;
