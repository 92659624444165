import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import { colors } from '../helper/variables';
import { mq } from '../helper/stylehelper';
import { H4, P } from './typography';

const Wrapper = styled.li`
    position: relative;
    padding: 0 0 2em 0;
    transition: all 0.5s;
    list-style: none;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${mq.medium`
        flex: 1 0 49%;
    `};

    ${mq.large`
        flex: 1 0 30%;
        padding: 0 ${rem(15)} 3em;
    `};

    &:last-child {
        padding-bottom: 0;
    }
`;

const Title = styled(H4)`
    color: ${colors.blueDark};
    margin-bottom: 0;
`;

const StyledImage = styled(Img)`
    width: ${props => rem(props.fluid.aspectRatio * 310)};
    margin-bottom: 1em;
    max-width: ${rem(220)};

    ${mq.medium`
        max-width: ${rem(280)};
    `};

    ${mq.large`
        max-width: none;
    `};
`;

const propTypes = {
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.shape().isRequired,
};

const defaultProps = {
    description: null,
};

/**
 *
 */
const ProductCard = ({ title, slug, description, image }) => (
    <Wrapper id={slug}>
        {image != null && <StyledImage fluid={image.fluid} alt={image.alt} title={image.title} />}
        <Title textCentered>{title}</Title>
        {description && <P textCentered>{description}</P>}
        <script type="application/ld+json">
            {`
                {
                    "@context": "http://schema.org/",
	                "@type": "Product",
	                "description": "${description}",
                    "name": "${title}",
                    "image":"${image.fluid.src}"
                }
            `}
        </script>
    </Wrapper>
);

ProductCard.propTypes = propTypes;
ProductCard.defaultProps = defaultProps;

export default ProductCard;
