import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { mq } from '../helper/stylehelper';

const StickyNav = styled(({ className, children }) => <nav className={className}>{children}</nav>)`
    display: inline-block;
    height: 0; // damit kein Leerraum im Layout entsteht - Content sollte die Höhe auf 3em setzen
    overflow: visible;
    position: relative;
    position: sticky;
    transform: translateY(-50%);
    top: 0;
    width: 90%;
    width: calc(100% - ${rem(20)});
    z-index: 1;

    ${mq.smallOnly`margin: 0 ${rem(10)}`};

    ${mq.medium`
        left: 50%;
        transform: translate(-50%, -1.2em);
        top: 1.2em;
        width: ${rem(300)};
    `};
`;

StickyNav.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default StickyNav;
